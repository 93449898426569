import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as sha1 from 'sha1';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  // encapsulation: ViewEncapsulation.Nonesds
})
export class LoginComponent implements OnInit {
  public hide = true;
  public cambioPass: boolean = false;
  public correo = new FormControl('', Validators.required);
  public numeroColegiacion = new FormControl('', [Validators.required]);
  public vistaCambioPass: string = 'numeroColegiacion';

  public formRecuperarPass: FormGroup;
  public datosUsuario: any;

  menu = [
    {
      "idMenu": 26022,
      "descripcion": "Afiliados",
      "texto": "Pagos",
      "idPadre": 0,
      "icono": "fas fa-users",
      "url": "",
      "visible": true,
      "activo": true,
      "subItems": true,
      "idModulo": 4002,
      "hijos": [
        {
          "idMenu": 26023,
          "idPadre": 26022,
          "texto": "Registrar un pago",
          "descripcion": "Visualizar el listado de los afiliados",
          "icono": "fas fa-id-card-alt",
          "url": "/template/afiliados/posteo-pagos",
          "visible": true,
          "activo": true,
          "subItems": true
        },
        {
          "idMenu": 27033,
          "idPadre": 26022,
          "texto": "Ver estado de cuentas",
          "descripcion": "solitudes de ingresar pagos",
          "icono": "fa fa-file",
          "url": "/affiliates/solicitudes",
          "visible": true,
          "activo": true,
          "subItems": true
        },
        {
          "idMenu": 27033,
          "idPadre": 26022,
          "texto": "Realizar pago",
          "descripcion": "solitudes de ingresar pagos",
          "icono": "fa fa-file",
          "url": "/affiliates/solicitudes",
          "visible": true,
          "activo": true,
          "subItems": true
        }
      ]
    },
    {
      "idMenu": 27031,
      "descripcion": "mantenimientos del modulo de afiliados",
      "texto": "Solicitudes",
      "idPadre": 0,
      "icono": "fa fa-check",
      "url": "",
      "visible": true,
      "activo": true,
      "subItems": true,
      "idModulo": 4002,
      "hijos": [
        {
          "idMenu": 27032,
          "idPadre": 27031,
          "texto": "Constancia",
          "descripcion": "mantenimiento de las clinicas",
          "icono": "fa fa-clinic-medical",
          "url": "affiliates/clinica",
          "visible": true,
          "activo": true,
          "subItems": true
        },
        {
          "idMenu": 27034,
          "idPadre": 27031,
          "texto": "Certificados",
          "descripcion": "mantenimiento de conceptos del pago afiliados",
          "icono": "fas fa-money-bill-wave",
          "url": "affiliates/conceptosPago",
          "visible": true,
          "activo": true,
          "subItems": true
        },
        {
          "idMenu": 27035,
          "idPadre": 27031,
          "texto": "Solvencia",
          "descripcion": "mantenimientos estados de afiliacion",
          "icono": "fas fa-poll",
          "url": "affiliates/estadoAfiliacion",
          "visible": true,
          "activo": true,
          "subItems": true
        },
      ]
    }
  ];

  public formLogin: FormGroup;
  public suscripciones: Subscription = new Subscription();
  private url: string = "";

  constructor(private toastr: ToastrService, public authService: AuthService, public router: Router, public toast: ToastrServiceLocal) {
    this.formLogin = new FormGroup({
      usuario: new FormControl('', [Validators.required]),
      clave: new FormControl('', [Validators.required])
    });

    this.url = document.location.href;

  }

  ngOnInit(): void {

    localStorage.setItem('menus', JSON.stringify(this.menu));

  }

  login() {
    if (this.formLogin.invalid) {
      this.formLogin.markAllAsTouched();
      return;
    }
    this.authService.LoginPublic({
      clave: sha1(this.formLogin.value.clave),
      usuario: this.formLogin.value.usuario,
      idTipoUsuario: 2
    });
  }

  loguearse() {
    this.router.navigateByUrl('afiliados/mi-perfil');
  }

  confirmarDatosColegiacion() {
    if (this.numeroColegiacion.invalid) {
      this.numeroColegiacion.markAllAsTouched();
      this.toast.mensajeWarning('Es requerido ingresar el numero de colegiación', '');
      return;
    }

    this.authService.recuperarInformacionUsuario(this.numeroColegiacion.value, (result) => {
      if (result) {
        this.datosUsuario = result[0];
        console.log(this.datosUsuario);
        this.vistaCambioPass = 'confirmarDatos';
        this.construirFormularioPass();
      }
    });

  }

  construirFormularioPass() {
    this.formRecuperarPass = new FormGroup({
      idPersona: new FormControl(this.datosUsuario?.idPersona),
      Usuario: new FormControl(this.datosUsuario?.Usuario),
      noIdentificacion: new FormControl('', [Validators.required]),
      correoElectronico: new FormControl(''),
      fechaNacimiento: new FormControl(this.datosUsuario?.fechaNacimiento),
      telefono: new FormControl(''),
      celular: new FormControl(''),
    });
  }

  public camposValidar = ['noIdentificacion', 'correoElectronico', 'telefono', 'celular'];
  public numeroCamposSolicitados = 2;

  validarFormularioCambioPass() {
    let numeroCamposInvalidos = 0;
    let camposInvalidos = [];

    for (const campo of this.camposValidar) {
      if (this.formRecuperarPass.get(campo).value === '') {
        numeroCamposInvalidos++;
        camposInvalidos.push(campo);
      } 
    }

    if (numeroCamposInvalidos > this.numeroCamposSolicitados) {
      this.toast.mensajeWarning('Debes completar por lo menos dos de los campos solicitados', '');
      for (const invalido of camposInvalidos) {
        this.formRecuperarPass.get(invalido).setErrors({ 'incorrect': true });
        this.formRecuperarPass.get(invalido).markAllAsTouched();
        this.formRecuperarPass.get(invalido).markAsDirty();
      };
      return;
    }

    this.authService.validacionInformacionUsuario(this.formRecuperarPass.value, (result)=>{
      if(result){
        this.toast.mensajeSuccess('Información correcta se envió la contraseña a su correo electrónico', '');
        this.cambioPass = false;
      }
    })
  }
  
  regresarCambioPass(){
    this.cambioPass = false;
    this.vistaCambioPass = 'numeroColegiacion';
  }

  recuperarUsuario() {
    this.router.navigateByUrl('/recuperacionUsuario');
  }

}
